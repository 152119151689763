/**
 * In IE11, elements that wrap images will get their height calculated by the natural height of the image, not the
 * actual visible height. This module fixes that by forcing the correct height via inline styles.
 */
module.exports = function() {

	'use strict';

	var flexBoxIeBugfix = {},
		$ = require('jquery');

	function init() {
		// only activate if IE11 is detected
		if (!(window.ActiveXObject) && 'ActiveXObject' in window) {
			fixImageHeights();
			$(window).on('resize', fixImageHeights);
		}
	}

	function fixImageHeights() {
		$('.equal-height-columns .ce-gallery img').each(function(index, item) {
			var $item = $(item);
			// reset former styles to get the actual height
			$item.css({height: ''});
			$item.css({height: $item.height() + 'px'});
		});
	}

	init();
	return flexBoxIeBugfix;
};
