module.exports = function(_navigation, _screenMinWidth) {

	'use strict';

	var stickyNavigation = {},
		$navigation,
		$ = require('jquery'),
		navigationHeight,
		navigationOffset,
		lastScrollTop = $(document).scrollTop(),
		locked = false;

	function init() {
		$navigation = $(_navigation);
		navigationHeight = $navigation.height();
		navigationOffset = $navigation.offset().top;
		$(window).on('scroll', checkStickyStatus);
	}

	function checkStickyStatus() {
		var currentScrollTop = $(document).scrollTop(),
			currentBrowserWidth = $(document).width();
		if ($(window).scrollTop() > navigationHeight + navigationOffset && !stickyNavigation.isSticky() && currentBrowserWidth >= _screenMinWidth) {
			stickyNavigation.enable();
		} else if ($(window).scrollTop() < navigationHeight + navigationOffset && stickyNavigation.isSticky() && currentBrowserWidth >= _screenMinWidth) {
			stickyNavigation.disable();
		} else if (currentBrowserWidth <= _screenMinWidth && stickyNavigation.isSticky()) {
			stickyNavigation.disable();
		}
		lastScrollTop = currentScrollTop;
	}

	stickyNavigation.isSticky = function() {
		return $navigation.hasClass('default-navbar-sticky');
	};

	stickyNavigation.enable = function() {
		if (locked) {
			return;
		}
		var $dummyElement = $(document.createElement('div'));
		// set padding to parent element to compensate for missing navbar
		$navigation.parent().css({
			'padding-bottom': navigationHeight + 'px'
		});
		// add sticky class and place navbar above the viewport
		$navigation.addClass('default-navbar-sticky').css({
			'transform': 'translateY(-' + navigationHeight + 'px)'
		});
		// create a dummy element to perform the animation calculation on
		$dummyElement.css({
			'top': navigationHeight
		});
		// perform the animation
		$dummyElement.animate({top: 0}, {
			step: function(now) {
				$navigation.css({
					'transform': 'translateY(-' + parseInt(now) + 'px)'
				});
			},
			duration: 'fast'
		}, 'linear').promise().then(function() {
			// cleanup
			$dummyElement.remove();
		});
	};

	stickyNavigation.disable = function() {
		if (locked) {
			return;
		}
		locked = true;
		$navigation.parent().css({
			'padding-bottom': ''
		});
		$navigation.removeClass('default-navbar-sticky').hide().fadeIn('fast').promise().then(function() {
			locked = false;
			// reset altered values
			$navigation.css({'display': '', 'transform': ''});
		});
	};

	init();
	return stickyNavigation;

};
