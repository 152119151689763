module.exports = function(_element, _config) {
	'use strict';

	var $ = require('jquery'),
		Preloader = {},
		$element,
		config = {
			classPrefix: ''
		};

	function init() {
		$.extend(config, _config);
		$element = $(_element);
		if ($element.css('display') === 'inline') {
			$element.addClass(config.classPrefix + 'preloader-display-helper');
		}
		if ($element.get(0) === $('body').get(0)) {
			$element.addClass(config.classPrefix + 'preloader-fixed');
		}
		$element.addClass(config.classPrefix + 'preloader');
	}

	Preloader.remove = function() {
		$element.removeClass(config.classPrefix + 'preloader ' + config.classPrefix + 'preloader-display-helper ' + config.classPrefix + 'preloader-fixed');
	};

	init();
	return Preloader;
};