/**
 * This module handles the main navigation bar
 *
 * @param {string} _element
 * @param {object} _config
 * @returns {object}
 */
module.exports = function(_element, _config) {
	'use strict';

	var mainNavigation = {},
		$ = require('jquery'),
		$element;

	function init() {
		$element = $(_element);

		handleActiveState();
		if (_config.hasOwnProperty('openDropDownLinksOnClick') && _config.openDropDownLinksOnClick) {
			$element.find('.dropdown-toggle').on('click', openBlockedLink);
		}
	}

	/**
	 * This function opens links that would otherwise be blocked by the default Bootstrap JS
	 *
	 * @param _event
	 */
	function openBlockedLink(_event) {
		_event.preventDefault();
		window.location = _event.currentTarget.getAttribute('href');
	}

	/**
	 * Handles the marking of active navigation items
	 */
	function handleActiveState() {
		var url = window.location.pathname,
			$menuElements = $element.find('li[class!="doktype-4"] > a'),
			segments = window.location.pathname.split('/').reverse();

		segments.forEach(function() {
			// Note: Change this value if you want to do an active check also on a high level of the url.
			// This can lead to unwanted results. The current value is chosen to prevent the highlighting
			// of the language level pages like the HOME entry.
			if (url.split('/').length <= 2) {
				return;
			}

			$menuElements.each(function(index, item) {
				var $item = $(item);
				if (url + '/' === $item.attr('href')) {
					$item.parent().addClass('active');
				}
			});
			url = url.substr(0, url.lastIndexOf('/'));
		});
	}

	init();
	return mainNavigation;
};
