module.exports = function(_selector) {

	'use strict';

	var smartLabels = {},
		$labels,
		$ = require('jquery');

	function init() {
		$labels = $(_selector);
		$labels.each(function(index, label) {
			$('#' + $(label).attr('for')).on('keyup blur change', updateInputState);
		});
	}

	function updateInputState(event) {
		var $input = $(event.currentTarget);
		if ($input.val() !== '' && $input.val() !== null) {
			$input.prev('label').addClass('filled');
		} else {
			$input.prev('label').removeClass('filled');
		}
	}

	init();
	return smartLabels;
};
