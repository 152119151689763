/**
 * A ContentSlideManager can be used to control a set of ContentSlides
 *
 * @param {object} _slides An iterable object containing ContentSlide root elements
 * @returns {object}
 * @constructor
 */
module.exports = function(_slides) {

	'use strict';

	var slides = {},
		$ = require('jquery'),
		ContentSlideManager = {},
		ContentSlide = require('./ContentSlide'),
		transitions = {
		slideDownwards: {
			className: 'default-accordion-transition-slide-downwards',
			onTransition: function(content, action) {
				if (action === 'expand') {
					content.slideDown();
				} else {
					content.slideUp();
				}
			},
			cssTransition: false
		},
		slideUpwards: {
			className: 'default-accordion-transition-slide-upwards',
			onTransition: function(content, action) {
				if (action === 'expand') {
					content.slideDown();
				} else {
					content.slideUp();
				}
			},
			cssTransition: false
		},
		fade: {
			className: 'default-accordion-transition-fade',
			cssTransition: true
		},
		// fallback transition that relies on JavaScript only
		fallback: {
			className: 'default-accordion-transition-fallback',
			onTransition: function(content) {
				content.slideToggle();
			}
		}
	};

	/**
	 * Initialize the ContentSlideManager
	 */
	function init() {
		ContentSlideManager.addSlides(_slides);
		hashChanged();
		$(window).on('hashchange', hashChanged);
	}

	/**
	 * Expands the slide associated with the current hash
	 */
	function hashChanged() {
		var hash = window.location.hash;
		if (hash in slides) {
			slides[hash].expand();
		}
	}

	/**
	 * Add slides to the ContentSlideManager
	 *
	 * @param _slides {Object} An iterable object containing the slideRoots
	 */
	ContentSlideManager.addSlides = function(_slides) {
		_slides.each(
			function() {
				var slideObject = $('.default-accordion-toggle a', this).get(0);
				if (typeof slideObject !== 'undefined') {
					slides['#' + slideObject.id] = new ContentSlide(this, transitions);
				}
			}
		);
	};

	/**
	 * Expands all managed Slides
	 */
	ContentSlideManager.expandAll = function() {
		for (var slideId in slides) {
			if (slides.hasOwnProperty(slideId)) {
				slides[slideId].expand();
			}
		}
	};

	/**
	 * Collapses all managed Slides
	 */
	ContentSlideManager.collapseAll = function() {
		for (var slideId in slides) {
			if (slides.hasOwnProperty(slideId)) {
				slides[slideId].collapse();
			}
		}
	};

	/**
	 * Get the ContentSlide object associated with _hash
	 * @param _hash {String} the hash of the desired ContentSlide
	 * @returns {Object} the ContentSlide object
	 */
	ContentSlideManager.getSlide = function(_hash) {
		return slides[_hash];
	};

	/**
	 * Get all available slide Objects
	 *
	 * @returns {Object} an object containing all ContentSlide objects
	 */
	ContentSlideManager.getAllSlides = function() {
		return slides;
	};

	init();

	return ContentSlideManager;
};
