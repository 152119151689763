/**
 * A ContentSlide object represents a single slide instance
 *
 * @param {HTMLElement} _slideRoot The slides root element
 * @param {object} _transitions The object containing all transitions
 * @returns {object}
 * @constructor
 */
module.exports = function(_slideRoot, _transitions) {

	'use strict';

	var $ = require('jquery'),
		toggle,
		content,
		ContentSlide = {},
		lastTransitionClassName,
		options = {
			transition: 'slideDownwards'
		};

	/**
	 * Initialize this ContentSlide
	 */
	function init() {
		toggle = $('.default-accordion-toggle', _slideRoot).first();
		_slideRoot = $(_slideRoot);

		initFromDataAttributes();
		initContent();

		if (_slideRoot.hasClass('default-accordion-open')) {
			loadContent().then(ContentSlide.expand);
		}

		// kill all default events on the toggle to prevent issues with selecting elements via keyboard
		$('a', toggle).off('click').on(
			'click', function(_event) {
				_event.preventDefault();
			}
		);
		// attach toggle event listener
		toggle.on('click', ContentSlide.toggle);
	}

	function initContent() {
		content = $('.default-accordion-content', _slideRoot).first();
		setTransition();
		$(ContentSlide).on('optionsChange', setTransition);
	}

	/**
	 * Set options via configuration data-attributes on _slideRoot
	 */
	function initFromDataAttributes() {
		var slideAnimationName = _slideRoot.data('default-accordion-animation');
		if (typeof slideAnimationName !== 'undefined' && slideAnimationName in _transitions) {
			ContentSlide.setOptions({transition: slideAnimationName});
		}
	}

	/**
	 * Set the given transition for this ContentSlide
	 */
	function setTransition() {
		// remove old transition class
		if (lastTransitionClassName) {
			content.removeClass(lastTransitionClassName);
		}
		// set fallback transition if the selected one is not supported
		if (_transitions[options.transition].cssTransition && !$.support.transition) {
			options.transition = 'fallback';
		}
		// change the data-attribute if it is not valid anymore
		if (_slideRoot.data('default-accordion-animation') !== options.transition) {
			// this needs to be done via attr, since data() doesn't update the html attribute
			_slideRoot.attr('data-default-accordion-animation', options.transition);
		}
		content.addClass(_transitions[options.transition].className);
		lastTransitionClassName = _transitions[options.transition].className;
	}

	/**
	 * This function checks if content is present and loads it via ajax if not.
	 *
	 * @returns {Promise} Promise resolves if content is present
	 */
	function loadContent() {
		var deferred = $.Deferred();
		// check if content is already there
		if (content.length === 0) {
			// display loading spinner while request is processed
			var spinner = $('<div class="default-accordion-spinner" />');
			_slideRoot.append(spinner);

			// grab content via ajax
			$.ajax(
				{
					type: 'get',
					url: 'index.php?eID=default-accordion',
					data: 'accordion[id]=' + toggle.find('a').attr('id').substring(3)
				}
			).then(
				function(response) {
					response = $(response);
					spinner.remove();
					if (response.length > 0) {
						_slideRoot.append(response);
						initContent();
					}
					deferred.resolve();
				}
			);
		} else {
			// content is already there, nothing to do
			deferred.resolve();
		}

		return deferred.promise();
	}

	/**
	 * Expand the slide and trigger expand event
	 */
	ContentSlide.expand = function() {
		var transition = _transitions[options.transition];
		if (transition.onTransition) {
			transition.onTransition.call(this, content, 'expand');
		}
		_slideRoot.addClass('default-accordion-open');
		_slideRoot.trigger('expand', [toggle, content]);
	};

	/**
	 * Collapse the slide and trigger collapse event
	 */
	ContentSlide.collapse = function() {
		var transition = _transitions[options.transition];
		if (transition.onTransition) {
			transition.onTransition.call(this, content, 'collapse');
		}
		_slideRoot.removeClass('default-accordion-open');
		_slideRoot.trigger('collapse', [toggle, content]);
	};

	/**
	 * Toggle the slide
	 *
	 * @param _event {Event} The click event
	 */
	ContentSlide.toggle = function(_event) {
		_event.preventDefault();
		if (_slideRoot.hasClass('default-accordion-open')) {
			ContentSlide.collapse();
		} else {
			loadContent().then(ContentSlide.expand);
		}
	};

	/**
	 * Set options object. The provided options will be merged with the default ones.
	 *
	 * @param _optionsObject {Object} new options to be set
	 */
	ContentSlide.setOptions = function(_optionsObject) {
		$.extend(options, _optionsObject);
		$(ContentSlide).trigger('optionsChange');
	};

	/**
	 * Get the slide root element
	 *
	 * @returns {HTMLElement} the root DOM node of this slide
	 */
	ContentSlide.getSlideRoot = function() {
		return _slideRoot;
	};

	init();

	return ContentSlide;
};
