module.exports = function(_element) {

	'use strict';

	var cookieAlert = {},
		$ = require('jquery'),
		$element,
		Cookie = require('js-cookie');

	function init() {
		$element = $(_element);
		if (!Cookie.get('cookie_accepted')) {
			$element.removeClass('closed');
			$element.find('.btn').on('click', cookieAlert.accept);
		}
	}

	cookieAlert.accept = function(event) {
		event.preventDefault();
		Cookie.set('cookie_accepted', true);
		$element.addClass('closed');
	};

	init();
	return cookieAlert;
};
