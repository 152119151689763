module.exports = function(_slider) {

	'use strict';

	var bootstrapSliderTouch = {},
		$ = require('jquery'),
		Hammer = require('hammerjs'),
		$slider;

	function init() {
		$slider = $(_slider);
		new Hammer(_slider).on('swipeleft', swipeLeft);
		new Hammer(_slider).on('swiperight', swipeRight);
	}

	function swipeLeft() {
		$slider.carousel('next');
	}

	function swipeRight() {
		$slider.carousel('prev');
	}

	init();
	return bootstrapSliderTouch;
};
