module.exports = function(_selector, _options) {
	'use strict';

	var InlineType = {},
		$ = require('jquery'),
		Preloader = require('../../Preloader');

	require('magnific-popup');

	function init() {
		$(_selector).on('click', initPopupLink);
	}

	function initPopupLink(event) {
		event.preventDefault();
		var preloaderInstance = new Preloader($('body'), {classPrefix: 'ayi-'});
		$.ajax(
			{
				type: 'POST',
				url: $(event.currentTarget).attr('href'),
				data: {
					type: _options.ajaxPageType || 103
				}
			}
		).then(
			function(response) {
				preloaderInstance.remove();
				$.magnificPopup.open(
					$.extend(
						{
							items: {
								src: '<div class="ajax-wrapper">' + response + '</div>',
								type: 'inline'
							}
						}, _options
					)
				);
			}
		);
	}

	init();
	return InlineType;
};