'use strict';

var $ = require('jquery'),
	Shadowbox = require('./Modules/Shadowbox'),
	Accordion = require('./Modules/Accordion'),
	NewsCategoryFilter = require('./Modules/newsCategoryFilter'),
	DropUpComponent = require('./Modules/dropUpComponent'),
	GoogleMapsElement = require('./Modules/googleMapsElement'),
	SmartLabels = require('./Modules/smartLabels'),
	MainNavigation = require('./Modules/mainNavigation'),
	ResponsiveNavigation = require('./Modules/responsiveNavigation'),
	StickyNavigation = require('./Modules/stickyNavigation'),
	CookieAlert = require('./Modules/cookieAlert'),
	BootstrapSliderTouch = require('./Modules/bootstrapSliderTouch'),
	FlexboxIeBugfix = require('./Modules/flexboxIeBugfix');

// make jQuery available for plugins
global.jQuery = $;
global.$ = $;

$(document).ready(
	function() {
		// enable lightbox
		Shadowbox.bind(
			'.default-lightbox', Shadowbox.types.IMAGE, {
				mainClass: 'default-shadowbox',
				classPrefix: 'default-',
				gallery: {
					enabled: true
				}
			}
		);

		// init accordion
		new Accordion($('.default-accordion-wrap'));

		new FlexboxIeBugfix();
		new DropUpComponent('#search-trigger', '#search', 'drop-up-search');
		new DropUpComponent('#login-trigger', '#login', 'drop-up-login');

		new StickyNavigation('#main-navigation', 768);

		new NewsCategoryFilter();

		new SmartLabels('.smart-label');

		new MainNavigation('#mainMenu', {
			openDropDownLinksOnClick: true
		});
		
		new ResponsiveNavigation('#responsive-navigation', '#mainMenu');

		new CookieAlert('#cookie-alert');

		new GoogleMapsElement();

		// enable responsive behaviour for tables
		$('table').wrap('<div class="table-responsive" />');

		$('.carousel').each(function(index, item) {
			new BootstrapSliderTouch(item);
		});

	}
);

