module.exports = function(_navigation, _standardNavigation) {

	'use strict';

	var responsiveNavigation = {},
		$ = require('jquery'),
		$navigation,
		$standardNavigation,
		markup = '<ul>',
		DropUpComponent = require('./dropUpComponent');

	require('jquery.mmenu');

	function init() {
		$navigation = $(_navigation);
		$standardNavigation = $(_standardNavigation);
		createMenu();
		$navigation.mmenu({
			'offCanvas': {
				'position': 'right'
			}
		});
		new DropUpComponent('#responsive-login-trigger-mobile', '#login', 'drop-up-login');
		new DropUpComponent('#responsive-search-trigger-mobile', '#search', 'drop-up-search');
	}

	function createMenu() {
		$standardNavigation.find('ul').not('li ul').each(appendMenu);
		markup += '</ul>';
		$navigation.html(markup);
		$navigation.find('li').each(cleanItems);
		$navigation.find('li').each(adjustClassNames);
		$navigation.find('ul').each(adjustClassNames);
		$navigation.find('a').each(adjustIds);
		$navigation.find('a').each(checkLink);
	}

	function appendMenu(index, element) {
		markup += $(element).html();
	}

	function cleanItems(index, item) {
		var $item = $(item);
		if ($item.hasClass('hide-in-responsive-menu')) {
			$item.remove();
		}
	}

	function adjustClassNames(index, element) {
		var $element = $(element),
			highlight = false,
			activeItem = false;

		if ($element.hasClass('highlight-in-responsive-menu')) {
			highlight = true;
		}
		if ($element.hasClass('active')) {
			activeItem = true;
		}
		$element.removeClass();
		if (highlight) {
			$element.addClass('highlight-in-responsive-menu');
		}
		if (activeItem) {
			$element.addClass('active');
		}
		$element.find('a').removeClass('dropdown-toggle').removeAttr('aria-haspopup').removeAttr('data-toggle');
	}

	function checkLink(index, item) {
		var $item = $(item);
		if (window.location.pathname === $item.attr('href')) {
			$item.parent().addClass('active');
		}
	}

	function adjustIds(index, element) {
		var $element = $(element);
		if ($element.attr('id') !== '') {
			$element.attr('id', 'responsive-' + $element.attr('id'));
		}
	}

	init();
	return responsiveNavigation;

};
