module.exports = function() {
	'use strict';

	var $ = require('jquery');

	var sgnewsFilter = $('.tx-sgnews-categories-tabmenu li a');
	var sgnewsCat = $('.tx-sgnews-categories-tabcontents');

	sgnewsFilter.parent().first().addClass('active');
	sgnewsCat.children('div:not(:first-child)').hide();

	sgnewsFilter.click(
		function() {
			var sgnewsFilterClass = $(this).data('tab');

			sgnewsFilter.parent().removeClass('active');
			$(this).parent().addClass('active');

			sgnewsCat.children('div:not(#' + sgnewsFilterClass + ')').hide();
			sgnewsCat.children('div#' + sgnewsFilterClass).show();

			return false;
		}
	);
};
