module.exports = function(_selector, _options) {
	'use strict';

	var ImageType = {},
		$ = require('jquery'),
		Preloader = require('../../Preloader'),
		$selector;

	require('magnific-popup');

	function init() {
		$selector = $(_selector);
		$selector.on('click', initZoomableImage);
	}

	function initZoomableImage(event) {
		event.preventDefault();
		var $target = $(event.currentTarget),
			preloaderInstance = new Preloader(event.currentTarget, {classPrefix: _options.classPrefix || ''}),
			galleryMode = _options.gallery && _options.gallery.enabled,
			$localSelector = galleryMode ? $selector : $target;
		preloadImage(event.currentTarget.href).then(
			function() {
				$localSelector.magnificPopup(
					$.extend(
						{
							type: 'image',
							mainClass: 'shadowbox',
							zoom: {
								enabled: true,
								duration: 300,
								easing: 'ease'
							},
							image: {
								titleSrc: function(item) {
									return item.el.next('figcaption').text() || '';
								}
							}
						}, _options
					)
				);
				if (galleryMode) {
					$target.magnificPopup('open', $selector.index($target));
				} else {
					$target.magnificPopup('open');
				}
				preloaderInstance.remove();
				$localSelector.off('click', initZoomableImage);
			}
		);
	}

	function preloadImage(imageUrl) {
		var $image = $(new Image()),
			deferred = $.Deferred();

		$image.on(
			'load', function() {
				deferred.resolve();
			}
		);
		$image.attr('src', imageUrl);

		return deferred.promise();
	}

	init();
	return ImageType;
};