module.exports = function() {

	'use strict';

	var googleMapsElement = {},
		$ = require('jquery'),
		GoogleMapsLoader = require('google-maps');

	function init() {
		// google maps mask element
		GoogleMapsLoader.load(function(google) {
			$('.google-maps-container').each(function(index, item) {
				var $item = $(item);
				var map = new google.maps.Map(item, {
					center: {
						lat: parseFloat($item.data('lat')),
						lng: parseFloat($item.data('lng'))
					},
					scrollwheel: false,
					zoom: parseInt($item.data('zoom')),
					disableDefaultUI: $item.data('hideui')
				});
				$item.next('.google-maps-marker-container').find('.google-maps-marker').each(function(index, markerElement) {
					var $marker = $(markerElement),
						hideMarker = Boolean(parseInt($marker.data('hide'))),
						showInfoWindow = Boolean(parseInt($marker.data('showinfowindow'))),
						markerIcon = $marker.data('marker-icon'),
						marker = new google.maps.Marker({
							map: map,
							position: {
								lat: parseFloat($marker.data('lat')),
								lng: parseFloat($marker.data('lng'))
							},
							icon: markerIcon
						});
					marker.setVisible(!hideMarker);
					var $infoWindow = $marker.find('.google-maps-marker-info-window');
					if ($infoWindow.length) {
						var infoWindow = new google.maps.InfoWindow({
							content: $infoWindow.html()
						});
						if (showInfoWindow) {
							infoWindow.open(map, marker);
						}
						marker.addListener('click', function() {
							infoWindow.open(map, marker);
						});
					}
					$infoWindow.remove();
				});
			});
		});
	}

	init();
	return googleMapsElement;
};
