module.exports = function(_selector, _options) {
	'use strict';

	var IframeType = {},
		$ = require('jquery');

	require('magnific-popup');

	function init() {
		$(_selector).magnificPopup(
			$.extend(
				{
					type: 'iframe',
					mainClass: 'shadowbox'
				}, _options
			)
		);
	}

	init();
	return IframeType;
};