module.exports = function(_trigger, _target, _name) {
	'use strict';

	var $ = require('jquery');

	var DropUpComponent = {},
		$trigger,
		$target;

	// store name to identify component in global array
	DropUpComponent.name = _name;

	function init() {
		$trigger = $(_trigger);
		$trigger.on('click', DropUpComponent.togglePanel);
		$target = $(_target);
		$target.find('.drop-up-component-close').on('click', DropUpComponent.closePanel);
		// store component in global array in order to close other components on open
		global.dropUpComponents = global.dropUpComponents || [];
		global.dropUpComponents.push(DropUpComponent);
	}

	DropUpComponent.togglePanel = function(event) {
		event.preventDefault();
		// check if component is about to open and close all other components that are currently open
		if (!$('body').hasClass(_name + '-open')) {
			for (var i = global.dropUpComponents.length; i--;) {
				if (global.dropUpComponents[i].name !== _name) {
					// pass the event through, just to prevent an error on line 38
					global.dropUpComponents[i].closePanel(event);
				}
			}
		}
		$('body').toggleClass(_name + '-open');
	};

	DropUpComponent.closePanel = function(event) {
		event.preventDefault();
		$('body').removeClass(_name + '-open');
	};

	init();
	return DropUpComponent;
};
