/***************************************************************************
 *  Copyright notice
 *
 *  (c) sgalinski Internet Services (https://www.sgalinski.de)
 *
 *  All rights reserved
 *
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential!
 ****************************************************************************/

module.exports = function(_slides) {
	'use strict';

	var $ = require('jquery'),
		ContentSlideManager = require('./ContentSlideManager');

	$.support.transition = (function() {
		var thisBody = document.body || document.documentElement,
			thisStyle = thisBody.style;

		return thisStyle.transition !== undefined || thisStyle.WebkitTransition !== undefined ||
			thisStyle.MozTransition !== undefined || thisStyle.MsTransition !== undefined ||
			thisStyle.OTransition !== undefined;
	})();

	new ContentSlideManager(_slides);
};
