module.exports = (function() {
	'use strict';

	var Shadowbox = {};

	Shadowbox.types = {
		IMAGE: require('./Types/ImageType'),
		IFRAME: require('./Types/IframeType'),
		INLINE: require('./Types/InlineType')
	};

	Shadowbox.bind = function(_selector, _type, _options) {
		return _type(_selector, _options);
	};

	return Shadowbox;
})();